import {Link} from "gatsby";
import * as React from "react";
import {Seo} from "../components/Seo";
import {routes} from "../util/routes";

const NotFoundPage = () => {
    return (
        <>
            <Seo title="404" errorPage={true} description="Yikes, your not supposed to see this."/>
            <section className="flex items-center h-full p-16 dark:text-gray-300">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center flex flex-col">
                        <h2 className="font-extrabold text-9xl">
                            <span className="sr-only">Error</span>404
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl mb-5">
                            Page not found.
                        </p>
                        <p className="mb-5 text-coolGray-600 items-center text-left text-lg">
                            Yikes, your not supposed to see this. Let's go back and
                            try again. 😅
                        </p>
                    </div>
                    <Link
                        to={routes.home}
                        className="px-8 py-3 font-bold rounded bg-blue-600 text-white hover:bg-blue-600 dark:hover:bg-blue-600 shadow"
                    >
                        Back to home
                    </Link>
                </div>
            </section>
        </>
    );
};

export default NotFoundPage;
